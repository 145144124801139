/* eslint no-extra-parens: 0 */

// Globals needed by hive-bee library
(window as any).global = window;
(window as any).process = { env: {}, version: 'v12.22.6' };

declare var require: any;

(window as any).global.Buffer = (window as any).global.Buffer || require('buffer').Buffer;


